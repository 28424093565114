function openModalBox () {
    const modalBox = document.querySelector(".modal-box");
    modalBox.style.display = "block";
}

function closeModalBox () {
    const modalBox = document.querySelector(".modal-box");
    modalBox.style.display = "none";
}
const buttonWechat = document.querySelectorAll(".button-wechat");
for (const btn of buttonWechat) {
    btn.addEventListener("click", openModalBox);
}
const close = document.querySelector(".close");
close.addEventListener("click", closeModalBox);
const buttonClose = document.querySelector(".button-close");
buttonClose.addEventListener("click", closeModalBox);
